import { Store } from '@reduxjs/toolkit';
import { DataGridControl, DataGridSlice, DataGridState } from '../../../interfaces';

/*
 Label Control

 HTML:
 -----
 <div data-grid-control="label" data-type="pagination-pages|pagination-items|no-results"></div>

 Settings
 --------
 {
    renderLabelControl: (state, $control) => {
        return '...html...';
    }
 }
*/

import { pagination } from '../../actions/pagination/pagination';

window.datagridControls = window.datagridControls || {};

/**
 * render HTML
 */
const render = (state: DataGridState, $control: HTMLElement) => {
    const paging = pagination(state.currentPage, state.pageSize, state.totalSize, state.pagesRange);

    const type = $control.getAttribute('data-type');
    const text = $control.getAttribute('data-text');

    if(type === 'pagination-pages'){
        $control.classList.toggle('dg-label-empty', paging.pagesNumber <= 0);
        return paging.pagesNumber > 0 ? `Page ${paging.currentPage + 1} of ${paging.pagesNumber}` : '';
    }

    if(type === 'pagination-items'){
        $control.classList.toggle('dg-label-empty', paging.pagesNumber <= 0);
        return paging.totalSize > 0 ? `${paging.firstItemIndex + 1} - ${paging.lastItemIndex} of ${paging.totalSize}` : '';
    }

    if(type === 'no-results'){
        $control.classList.toggle('dg-label-empty', state.totalSize !== 0);
        return state.totalSize === 0 ? (text ? text : 'No results found.') : '';
    }

    return '';
};

/**
 * label control init
 * defined number of pagination items per page
 */
const init = (control: DataGridControl, store: Store, rootSlice: DataGridSlice) => {

    const state = store.getState();

    /*
    currentPage: 0
    firstItemIndex: 0
    lastItemIndex: 10
    nextPage: 1
    pageSize: 10
    pagesNumber: 10
    pagesRange: 10
    pagesRangeEnd: 9
    pagesRangeStart: 0
    prevPage: 0
    totalSize: 100
    */

    control.$control.innerHTML = typeof state.defaults.renderLabelControl === 'function' ? state.defaults.renderLabelControl(state, control.$control) : render(state, control.$control);

};

/**
 * page size control
 * defined number of pagination items per page
 */
window.datagridControls['label'] = (control: DataGridControl, store: Store, rootSlice: DataGridSlice) => {

    // re-initiate on each store update
    store.subscribe(() => {
        init(control, store, rootSlice);
    });

    // initiate first time
    init(control, store, rootSlice);
};