import { Store } from '@reduxjs/toolkit';
import { DataGridControl, DataGridSlice, FilterAction } from '../../../interfaces';

/*
 Text Filter Control

 HTML:
 -----
 <input
    type="text"
    value="..."
    placeholder="Text Filter by Title"
    data-grid-control="text-filter"
    data-id="..."
    data-path=".card-title"
    data-skip="..."
    data-mode="contains"
 />
*/

/**
 * Text filter control interface
 */
interface TextFilterControl extends HTMLElement {
    control: DataGridControl,
    store: Store,
    rootSlice: DataGridSlice,
    dataID: string,
    value: string,
}

window.datagridControls = window.datagridControls || {};

/**
 * update datagrid general state
 */
const update = ($control: TextFilterControl) => {

    const value = $control.value;
    const path = $control.getAttribute('data-path');
    const mode = $control.getAttribute('data-mode') || 'contains';
    const skip = $control.getAttribute('data-skip');

    // update the store -------------------------------------
    const control = $control.control;
    const store = $control.store;
    const rootSlice = $control.rootSlice;
    const { actions } = rootSlice;
    const { updateActions } = actions;

    const action: FilterAction = {
        control: control.type,
        dataID: $control.dataID,
        value,
        path,
        mode
    };

    if(skip !== undefined && skip !== null){
        action.skip = skip;
    }

    store.dispatch(updateActions(action));
};

/**
 * on input event
 */
const onInput = (evt: Event) => {
    update(evt.currentTarget as TextFilterControl);
};

/**
 * restore control state
 */
const restore = (control: DataGridControl, store: Store, action: FilterAction) => {
    if(!action) return;
    const $control = control.$control as TextFilterControl;
    $control.value = action.value;
};

/**
 * text filter control
 */
window.datagridControls['text-filter'] = (control: DataGridControl, store: Store, rootSlice: DataGridSlice) => {

    // re-initiate on each store update
    store.subscribe(() => {
        const state = store.getState();
        restore(control, store, state.actions[$control.dataID]);
    });

    const $control = control.$control as TextFilterControl;
    $control.control = control;
    $control.store = store;
    $control.rootSlice = rootSlice;
    $control.dataID = $control.getAttribute('data-id');

    const state = store.getState();

    if($control.dataID !== undefined && state.actions[$control.dataID] !== undefined){

        // restore control state first time
        restore(control, store, state.actions[$control.dataID]);
    }
    else{

        // if initial state is not defined in settings, or via deep link / storage, then
        // try to get it from HTML
        update($control);
    }

    $control.removeEventListener('input', onInput);
    $control.addEventListener('input', onInput);

    // returns an API ..........
    return {
        reset: () => {
            if($control.dataID && state.initialActions && state.actions[$control.dataID] !== undefined){
                restore(control, store, state.actions[$control.dataID]);
            }
            else{
                $control.value = $control.getAttribute('value') ?? '';
                update($control);
            }
        },
    }
};