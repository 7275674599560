import { IToken } from './token';

export enum ParseTreeNodeType {
    BinaryOperator = 'BinaryOperator',
    ID = 'ID',
}

export interface IParseTreeNode {
    type: ParseTreeNodeType;
    token: IToken;
    left?: IParseTreeNode;
    right?: IParseTreeNode;
    $girdItems?: HTMLElement[]|null;
    isEnabled: boolean;
}

export const createParseTreeNode = (type: ParseTreeNodeType, token: IToken, left?: IParseTreeNode, right?: IParseTreeNode) : IParseTreeNode => {
    const result : IParseTreeNode = {
        type,
        token,
        isEnabled: true,
    };

    if(left){
        result.left = left;
    }

    if(right){
        result.right = right;
    }

    return result;
};