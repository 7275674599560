import { Store } from 'redux';
import { DataGridControl, DataGridSlice } from '../../../interfaces';
import { scrollTop } from '../../helper'; 

/*
Page Size Control

HTML:
-----
<select class="form-control" data-grid-control="page-size" data-scroll-top>
    <option value="9">9 items per page</option>
    <option value="18">18 items per page</option>
    <option value="27">27 items per page</option>
</select>

Settings
--------
{
    pageSize: 10
}
*/

/**
 * Page size control interface
 */
interface PageSizeControl extends HTMLElement {
    store: Store,
    rootSlice: DataGridSlice,
    value: number,
    shouldScrollTop: boolean,
}

window.datagridControls = window.datagridControls || {};

/**
 * on change
 */
const onChange = (evt: Event) => {
    const $control = evt.currentTarget as PageSizeControl;

    const store = $control.store;
    const state = store.getState();

    const rootSlice = $control.rootSlice;
    const { actions } = rootSlice;
    const { updatePagination } = actions;

    const pageSize = Number($control.value);

    if($control.shouldScrollTop){
        scrollTop();
    }

    store.dispatch(updatePagination({
        currentPage: 0, // state.currentPage,
        pageSize: pageSize,
        pagesRange: state.pagesRange
    }));
};

/**
 * page size control
 * defined number of pagination items per page
 */
window.datagridControls['page-size'] = (control: DataGridControl, store: Store, rootSlice: DataGridSlice) => {

    // on change outside
    // for example, if there are multiple such control on the page, and one of them has changed
    store.subscribe(() => {
        const state = store.getState();
        $control.value = state.pageSize;
    });

    const state = store.getState();
    const $control = control.$control as PageSizeControl;

    $control.store = store;
    $control.rootSlice = rootSlice;
    $control.shouldScrollTop = control.$control.hasAttribute('data-scroll-top');

    // select initial value
    $control.value = state.pageSize;
    const initialPageSize = state.pageSize;

    $control.removeEventListener('change', onChange);
    $control.addEventListener('change', onChange);

    return {
        reset: () => {
            const state = store.getState();
            const { actions } = rootSlice;
            const { updatePagination } = actions;

            store.dispatch(updatePagination({
                currentPage: state.currentPage,
                pageSize: initialPageSize,
                pagesRange: state.pagesRange
            }));
        },
    }
};