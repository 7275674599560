import type { Store } from 'redux';
import { DataGridControl, DataGridSlice } from '../interfaces';

/**
 * get all controls from the page
 */
export const getControls = ($root: HTMLElement) : DataGridControl[] => {
    const controls = [];

    const DATA_ATTR = 'data-grid-control';
    const $controls = $root.querySelectorAll(`[${DATA_ATTR}]`) as NodeListOf<HTMLElement>;

    for(let i=0; i<$controls.length; i++){
        const $control = $controls[i];

        const type = $control.getAttribute(DATA_ATTR).trim();
        if(!type) continue;

        controls.push({
            type,
            $control
        });
    }

    return controls;
};

/**
 * Each control should have "data-id" attribute.
 * If the user does not define it, then it should be added dynamically.
 * The "data-id" attribute should be unique with the only exception:
 * when the same control appears in the top and the bottom panels at the same time.
 */
export const handleDataID = (controls: DataGridControl[]) => {
    let index = 0;

    for(let i=0; i<controls.length; i++){

        const control = controls[i];
        let dataID = control.$control.getAttribute('data-id');

        if(dataID === null || dataID === undefined){
            control.$control.setAttribute('data-id', index.toString());
            index++;
        }
    }
};

/**
 * render controls
 * @param {object} store
 * @param {object} rootSlice
 */
export const renderControls = (store: Store, rootSlice: DataGridSlice) => {

    const state = store.getState();

    for(let i=0; i<state.controls.length; i++){
        const control = state.controls[i];

        const controlFunc = window.datagridControls && window.datagridControls[control.type];
        if(!controlFunc) continue;

        control.$control.datagrid = controlFunc(control, store, rootSlice);
    }
};