import { createToken, IToken, TokenType } from './token';
import { isWhiteSpace } from '../../../helper';

export interface ILexerResult {
    tokens: IToken[];
    isError: boolean;
    errors: string[];
}

/**
 * Filter Lexer (Scanner).
 * ({...} or {...}) and {...}
 */
export const scan = (text: string) : ILexerResult => {
    let result: ILexerResult = {
        tokens: [],
        isError: false,
        errors: [],
    };

    /**
     * Handle case when the whole input is empty or whitespace.
     */
    if(isWhiteSpace(text)){
        return result;
    }

    // replace all newlines with spaces
    text = text.replace(/\n/g, ' ');

    let index = 0;

    // ----------------------------- HELPERS ---------------------------------------------

    /**
     * End of file token should always be added to the end of the tokens list.
     */
    const endOfFile = () => {
        return index >= text.length;
    };

    /**
     * In case of an error, the 'isError' and 'errors' properties should be updated.
     * No token is created.
     */
    const error = () => {
        result.isError = true;
        result.errors.push(`DataGrid Custom Filter Error: Bad character input: ${ text[index] }, index: ${ index }`);
    };

    /**
     * ID is any string inside { ... } and it represent jPlist data-id (or name in case Radio button filter).
     * It can be english letter, number, dash or underscore.
     */
    const isId = (char: string) => {
        return /^[a-zA-Z0-9-_]+$/.test(char);
    };

    const getLastIdToken = () => {
        let lastToken = result.tokens.length > 0 ? result.tokens[result.tokens.length - 1] : null;
        if(lastToken !== null && lastToken.type === TokenType.ID) return lastToken;

        lastToken = createToken(TokenType.ID, index, '');
        result.tokens.push(lastToken);
        return lastToken;
    };

    const isAnd = (startIndex: number) => {
        return text.substring(startIndex, startIndex + 3) === 'and';
    };

    const isOr = (startIndex: number) => {
        return text.substring(startIndex, startIndex + 2) === 'or';
    };

    // --------------------- LEXER FLOW -------------------------------

    const start = () => {
        result = {
            tokens: [],
            isError: false,
            errors: [],
        };

        if(endOfFile()) return result;

        const char = text[index];

        switch(char) {
            case ' ': {
                index++;
                start();
                break;
            }
            case '{': {
                openBracket();
                break;
            }
            case '(': {
                openParentheses()
                break;
            }
            default: {
                error();
                break;
            }
        }
    };

    /**
     * '{'
     */
    const openBracket = () => {
        index++;

        // ' .... {' - any string that ends with '{' character is not valid.
        if(endOfFile()) return error();

        const char = text[index];

        if(char === ' '){
            index++;
            openBracketWhiteSpace();
        }
        else if(isId(char)){
            id();
        }
        else error();
    };

    const openBracketWhiteSpace = () => {

        // ' .... { ' - any string that ends with '{ ' character and any number of white spaces after it ----> is not valid.
        if(endOfFile()) return error();

        const char = text[index];

        if(char === ' '){
            index++;
            openBracketWhiteSpace();
        }
        else if(isId(char)){
            id();
        }
        else error();
    };

    const id = () => {
        const idChar = text[index];
        const lastIdToken = getLastIdToken();
        lastIdToken.value += idChar;

        index++;
        const char = text[index];

        // ' .... aaa ' - any string that ends with a letter/number/dash/underscore character and any number of white spaces after it ----> is not valid.
        if(endOfFile()) return error();

        if(char === ' ') idWhiteSpace();
        else if(isId(char)) id();
        else if(char === '}') closeBracket();
        else error();
    };

    const idWhiteSpace = () => {
        // ' .... aaa ' - any string that ends with a letter/number/dash/underscore character and any number of white spaces after it ----> is not valid.
        if(endOfFile()) return error();

        const char = text[index];

        switch(char){
            case ' ':{
                index++;
                idWhiteSpace();
                break;
            }
            case '}': {
                closeBracket();
                break;
            }
            default: {
                error();
                break;
            }
        }
    };

    /**
     * '}'
     */
    const closeBracket = () => {
        index++;

        if(endOfFile()) return result;

        const char = text[index];

        switch (char){
            case ' ': {
                index++;
                closeBracketWhiteSpace();
                break;
            }
            case ')': {
                closeParentheses();
                break;
            }
            default: {
                error();
                break;
            }
        }
    };

    const closeBracketWhiteSpace = () => {

        if(endOfFile()) return result;

        const char = text[index];

        if(char === ' '){
            index++;
            closeBracketWhiteSpace();
        }
        else if(isAnd(index)){
            and();
        }
        else if(isOr(index)){
            or();
        }
        else if(char === ')') {
            closeParentheses();
        }
        else error();
    };

    const and = () => {
        result.tokens.push(createToken(TokenType.And, index));
        index += 3;

        if(endOfFile()) return error();

        const char = text[index];

        if(char === ' ') andOrWhiteSpace();
        else error();
    };

    const or = () => {
        result.tokens.push(createToken(TokenType.Or, index));
        index += 2;

        if(endOfFile()) return error();

        const char = text[index];

        if(char === ' ') andOrWhiteSpace();
        else error();
    };

    const andOrWhiteSpace = () => {

        index++;
        if(endOfFile()) return error();

        const char = text[index];

        switch (char) {
            case ' ': {
                andOrWhiteSpace();
                break;
            }
            case '{': {
                openBracket();
                break;
            }
            case '(': {
                openParentheses();
                break;
            }
            default: {
                error();
                break;
            }
        }
    };

    /**
     * '('
     */
    const openParentheses = () => {
        result.tokens.push(createToken(TokenType.OpenParentheses, index));
        index++;

        // ' .... (' - any string that ends with '(' character is not valid.
        if(endOfFile()) return error();

        const char = text[index];

        switch(char){
            case '(': {
                openParentheses();
                break;
            }
            case ' ': {
                index++;
                openParenthesesWhiteSpace();
                break;
            }
            case '{': {
                openBracket();
                break;
            }
        }
    };

    const openParenthesesWhiteSpace = () => {
        if(endOfFile()) return error();

        const char = text[index];

        switch(char){
            case ' ': {
                index++;
                openParenthesesWhiteSpace();
                break;
            }
            case '(': {
                openParentheses();
                break;
            }
            case '{': {
                openBracket();
                break;
            }
        }
    };

    /**
     * ')'
     */
    const closeParentheses = () => {
        result.tokens.push(createToken(TokenType.CloseParentheses, index));
        index++;

        if(endOfFile()) return result;

        const char = text[index];

        if(char === ' '){
            index++;
            closeParenthesesWhiteSpace();
        }
        else if(char === ')') closeParentheses();
        else if(isAnd(index)){
            and();
        }
        else if(isOr(index)){
            or();
        }
        else error();
    };

    const closeParenthesesWhiteSpace = () => {
        if(endOfFile()) return result;

        const char = text[index];

        if(char === ' '){
            index++;
            closeParenthesesWhiteSpace();
        }
        else if(char === ')') closeParentheses();
        else if(isAnd(index)){
            and();
        }
        else if(isOr(index)){
            or();
        }
        else error();
    };

    start();

    return result;
};