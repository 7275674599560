import { Store } from 'redux';
import { DataGridControl, DataGridSlice, FilterAction } from '../../../interfaces';

/*
 Button Filter Control

 HTML:
 -----
 <a href="#" data-grid-control="button-filter" data-id="..." data-path=".card-title" data-inverted="false" data-checked="false" />
*/

/**
 * Button filter control interface
 */
interface ButtonFilterControl extends HTMLElement {
    control: DataGridControl,
    store: Store,
    rootSlice: DataGridSlice,
    dataID: string,
    checked: boolean
}

window.datagridControls = window.datagridControls || {};

/**
 * update datagrid general state
 */
const update = ($control: ButtonFilterControl) => {

    const path = $control.getAttribute('data-path');
    const inverted = $control.getAttribute('data-inverted') === 'true';
    const value = $control.getAttribute('data-value');

    // update the store -------------------------------------
    const control = $control.control;
    const store = $control.store;
    const rootSlice = $control.rootSlice;
    const { actions } = rootSlice;
    const { updateActions } = actions;

    const action: FilterAction = {
        control: control.type,
        dataID: $control.dataID,
        path: $control.checked ? path : ''
    };

    if(inverted){
        action.inverted = inverted;
    }

    if($control.checked && value !== undefined && value !== null){
        action.value = value;
        action.mode = $control.getAttribute('data-mode') || 'equal';

        const skip = $control.getAttribute('data-skip');
        if(skip !== undefined && skip !== null){
            action.skip = skip;
        }
    }

    store.dispatch(updateActions(action));
};

/**
 * on click event
 */
const onClick = (evt: Event) => {
    evt.preventDefault();

    const $control = evt.currentTarget as ButtonFilterControl;

    $control.checked = !$control.checked;
    $control.classList.toggle('dg-checked', $control.checked);

    update($control);
};

/**
 * restore control state
 */
const restore = (control: DataGridControl, store: Store, action: FilterAction) => {
    if(!action) return;

    const $control = control.$control as ButtonFilterControl;
    const path = action.path;
    $control.checked = path !== undefined && path !== null && path.trim() !== '';
    $control.classList.toggle('dg-checked', $control.checked);
};

/**
 * button filter control
 */
window.datagridControls['button-filter'] = (control: DataGridControl, store: Store, rootSlice: DataGridSlice) => {

    // re-initiate on each store update
    store.subscribe(() => {
        const state = store.getState();
        restore(control, store, state.actions[$control.dataID]);
    });

    const $control = control.$control as ButtonFilterControl;
    $control.control = control;
    $control.store = store;
    $control.rootSlice = rootSlice;
    $control.dataID = $control.getAttribute('data-id');
    $control.checked = $control.getAttribute('data-checked') === 'true';
    
    const state = store.getState();

    if($control.dataID !== undefined && state.actions[$control.dataID] !== undefined){

        // restore control state first time
        restore(control, store, state.actions[$control.dataID]);
    }
    else{

        // if initial state is not defined in settings, or via deep link / storage, then
        // try to get it from HTML
        update($control);
    }

    $control.removeEventListener('click', onClick);
    $control.addEventListener('click', onClick);

    // returns an API ..........
    return {
        reset: () => {
            if($control.dataID && state.initialActions && state.initialActions[$control.dataID] !== undefined){
                restore(control, store, state.initialActions[$control.dataID]);
            }
            else{
                $control.checked = $control.getAttribute('data-checked') === 'true';
                update($control);
            }
        },
    }
};