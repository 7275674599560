import { DataGridState } from '../interfaces';
import { stateToDeepLink } from './deep-link';

/**
 * get data from the give URL
 */
export const bringData = async (state: DataGridState) => {

    if(!('URL' in window)) return;

    const deepLink = stateToDeepLink(state);
    const url = new URL(state.defaults.dataSourceURL);
    url.search = deepLink;

    const response = await fetch(url.href);
    return response.json();
};