import { Store } from '@reduxjs/toolkit';
import { DataGridControl, DataGridSlice, FilterAction } from '../../../interfaces';

/*
 Select Filter Control

 HTML:
 -----
 <select data-grid-control="select-filter" data-id="...">
    <option data-path="">Filter by</option>
    <option data-path=".card-title" selected>Filter by title</option>
    <option data-path=".card-desc">Filter by description</option>
    <option data-path=".genres">Filter by genres</option>
 </select>
*/

/**
 * Select filter control interface
 */
interface SelectFilterControl extends HTMLElement {
    control: DataGridControl,
    store: Store,
    rootSlice: DataGridSlice,
    dataID: string,
    selectedIndex: number,
    options: HTMLElement[],
}

window.datagridControls = window.datagridControls || {};

/**
 * update datagrid general state
 */
const update = ($control: SelectFilterControl) => {

    const $option = $control.options[$control.selectedIndex] as HTMLElement;
    const path = $option.getAttribute('data-path');
    const inverted = $option.getAttribute('data-inverted') === 'true';
    const value = $option.getAttribute('data-value');

    // update the store -------------------------------------
    const control = $control.control;
    const store = $control.store;
    const rootSlice = $control.rootSlice;
    const { actions } = rootSlice;
    const { updateActions } = actions;

    const action: FilterAction = {
        control: control.type,
        dataID: $control.dataID,
        path
    };

    if(inverted){
        action.inverted = inverted;
    }

    if(value !== undefined && value !== null){
        action.value = value;
        action.mode = $option.getAttribute('data-mode') || 'equal';

        const skip = $option.getAttribute('data-skip');
        if(skip !== undefined && skip !== null){
            action.skip = skip;
        }
    }

    store.dispatch(updateActions(action));
};

/**
 * on change event
 */
const onChange = (evt: Event) => {
    update(evt.currentTarget as SelectFilterControl);
};

/**
 * restore control state
 */
const restore = (control: DataGridControl, store: Store, action: FilterAction) => {

    if(!action) return;

    const $control = control.$control as SelectFilterControl;
    const path = action.path;
    const inverted = action.inverted;

    const optionIndex = Array.from($control.options).findIndex(($option: HTMLElement) => {

        const oPath = $option.getAttribute('data-path');
        const oInverted = $option.getAttribute('data-inverted') === 'true';

        return oInverted ? (oPath === path && oInverted === inverted) : oPath === path;
    })

    if(optionIndex !== -1){
        $control.selectedIndex = optionIndex;
    }
};

/**
 * select filter control
 */
window.datagridControls['select-filter'] = (control: DataGridControl, store: Store, rootSlice: DataGridSlice) => {

    // re-initiate on each store update
    store.subscribe(() => {
        const state = store.getState();
        restore(control, store, state.actions[$control.dataID]);
    });

    const $control = control.$control as SelectFilterControl;
    $control.control = control;
    $control.store = store;
    $control.rootSlice = rootSlice;
    $control.dataID = $control.getAttribute('data-id');

    const state = store.getState();

    if($control.dataID !== undefined && state.actions[$control.dataID] !== undefined){

        // restore control state first time
        restore(control, store, state.actions[$control.dataID]);
    }
    else{

        // if initial state is not defined in settings, or via deep link / storage, then
        // try to get it from HTML
        update($control);
    }

    $control.removeEventListener('change', onChange);
    $control.addEventListener('change', onChange);

    return {
        reset: () => {
            if($control.dataID && state.initialActions && state.initialActions[$control.dataID] !== undefined){
                restore(control, store, state.initialActions[$control.dataID]);
            }
            else{
                const index = Array.from($control.options ?? []).findIndex($option => $option.hasAttribute('selected'));
                $control.selectedIndex = index == -1 ? 0 : index;
                update($control);
            }
        },
    }
};