export enum TokenType {
    OpenParentheses = 'OpenParentheses', // (
    CloseParentheses = 'CloseParentheses', // )
    And = 'And',
    Or = 'Or',
    ID = 'ID',
}

export interface IToken {
    type: TokenType;
    index: number;
    value?: string|number;
}

export const createToken = (type: TokenType, index: number, value?: string|number) : IToken => {
    const res: IToken = {
        type,
        index,
    };

    if(value !== undefined){
        res.value = value;
    }

    return res;
};