import { sort } from '../domain/actions/sort/sort';
import { textFilter, pathFilter, customAndOrFilter } from '../domain/actions/filter/filter';
import { pagination } from '../domain/actions/pagination/pagination';
import { getActionsByType } from '../domain/helper';
import { DataGridSlice, DataGridState, FilterAction, Grid, SortAction } from '../interfaces';
import { log } from '../domain/log';
import { Store } from 'redux';

const HIDE_ITEM_CLASS_NAME = 'data-grid-hide';

/**
 * get grid placeholder from the page
 */
export const getGrid = ($root: HTMLElement): Grid => {

    const GRID_DATA_ATTR = 'data-grid';
    const ITEM_DATA_ATTR = 'data-grid-item';

    const $grid = $root.querySelector(`[${GRID_DATA_ATTR}]`) as HTMLElement;

    if(!$grid){
        log("The container element with 'data-grid' attribute doesn't exist. Please check documentation https://docs.getdatagrid.com/");
        // it's not possible to continue
        return;
    }

    // collect grid items
    const $items = Array.from($grid.querySelectorAll(`[${ITEM_DATA_ATTR}]`)) as HTMLElement[];

    // set initial sort order; it's used to restore back the initial sort
    for(let i=0; i < $items.length; i++){
        $items[i].order = i;
    }

    return {
        $grid,
        $items
    };
};

/**
 * apply sort on grid items
 */
const applySort = (sortActions: SortAction[], $visibleItems: HTMLElement[], $grid: HTMLElement) => {

    $visibleItems = sort($visibleItems, sortActions);

    for(let i=0; i<$visibleItems.length; i++){
        const $item = $visibleItems[i];
        $grid.appendChild($item);
    }

    return $visibleItems;
};

/**
 * apply pagination on grid items
 * show only items in the range [paging.firstItemIndex, paging.lastItemIndex)
 */
const applyPagination = (state: DataGridState, $visibleItems: HTMLElement[], totalSize: number) => {

    const itemsAfterPaging = [];

    const paging = pagination(state.currentPage, state.pageSize, totalSize, state.pagesRange);

    for(let j=0; j<$visibleItems.length; j++){
        const $item = $visibleItems[j];
        if(j >= paging.firstItemIndex && j < paging.lastItemIndex){
            itemsAfterPaging.push($item);
        }
    }

    return itemsAfterPaging;
};

/**
 * apply filter on grid items
 */
const applyFilter = (filterActions: FilterAction[], $visibleItems: HTMLElement[], customFilter?: string) => {

    if(customFilter){
        return customAndOrFilter(customFilter, filterActions, $visibleItems);
    }

    for(let i=0; i<filterActions.length; i++){
        const item = filterActions[i];

        const hasValue = item.value !== undefined && item.value !== null;

        if(hasValue){
            $visibleItems = textFilter($visibleItems, item.value, item.path, item.mode, item.skip);
        }
        else{
            $visibleItems = pathFilter($visibleItems, item.path, item.inverted);
        }
    }

    return $visibleItems;
};

/**
 * show / hide grid items
 */
const showHideGridItems = ($allItems: HTMLElement[], $visibleItems: HTMLElement[]) => {

    // hide all items
    for(let i=0; i<$allItems.length; i++){
        const $item = $allItems[i];
        $item.classList.add(HIDE_ITEM_CLASS_NAME);
    }

    // show only "visible" items
    for(let i=0; i<$visibleItems.length; i++){
        const $item = $visibleItems[i];
        $item.classList.remove(HIDE_ITEM_CLASS_NAME);
    }
};

/**
 * show or hide (and update in general) grid items according to the state
 */
export const updateGridItems = (store: Store, rootSlice: DataGridSlice) => {

    const state = store.getState();

    const grid = state.grid;
    let $visibleItems = grid.$items;

    // apply filter on grid items
    const filterActions = getActionsByType(state.actions, 'filter');
    $visibleItems = applyFilter(filterActions, $visibleItems, state.defaults.customFilter);

    const newTotalSize = $visibleItems.length;

    // apply sort on grid items
    const sortActions = getActionsByType(state.actions, 'sort');
    $visibleItems = applySort(sortActions, $visibleItems, grid.$grid);

    // apply pagination on grid items
    $visibleItems = applyPagination(state, $visibleItems, newTotalSize);

    // show / hide grid items
    showHideGridItems(grid.$items, $visibleItems);

    if(state.totalSize !== newTotalSize){
        const { actions } = rootSlice;
        const { updateTotalSize } = actions;
        store.dispatch(updateTotalSize({
            totalSize: newTotalSize
        }));
    }

    return $visibleItems;
};