import { SortAction } from '../../../interfaces';

/**
 * sort by multiple parameters
 * @param girdItems
 * @param paths
 * @return sorted items
 */
export const sort = (girdItems: HTMLElement[], paths: SortAction[]) => {
    
    if(!paths || paths.length <= 0) return girdItems;

    const copy = [...girdItems];

    copy.sort((item1, item2) => {
        return sortHelper(item1, item2, paths, 0);
    });

    return copy;
};

/**
 * recursive sort helper
 * @param item1
 * @param item2
 * @param paths
 * @param pathIndex
 * @return 0 if equal, <0 if item1 < item2, >0 if item1 > item2
 */
const sortHelper = (item1: HTMLElement, item2: HTMLElement, paths: SortAction[], pathIndex: number) => {

    if(pathIndex >= paths.length) return 0;

    let result = 0;

    const path = paths[pathIndex];

    if(path.type === 'initial'){
        result = initialSort(item1, item2);
    }

    if(path.type === 'text'){
        result = textSort(item1, item2, path.path, path.direction, path.skip);
    }

    if(path.type === 'number'){
        result = numbersSort(item1, item2, path.path, path.direction);
    }

    if(result === 0){
        result = sortHelper(item1, item2, paths, pathIndex + 1);
    }

    return result;
};

/**
 * restore back the initial sort
 * @param item1
 * @param item2
 * @return 0 if equal, <0 if item1 < item2, >0 if item1 > item2
 */
const initialSort = (item1: HTMLElement, item2: HTMLElement) => {
    const order1 = Number(item1.order) || 0;
    const order2 = Number(item2.order) || 0;
    return order1 - order2;
};

/**
 * text sort
 * @param item1
 * @param item2
 * @param path - can be any CSS selector - https://developer.mozilla.org/en-US/docs/Learn/CSS/Introduction_to_CSS/Selectors;
 * empty path means the whole element
 * @param direction - asc or desc
 * @param skip - optional regex that defines characters that should be ignored before the sorting
 * @return 0 if equal, <0 if item1 < item2, >0 if item1 > item2
 */
const textSort = (item1: HTMLElement, item2: HTMLElement, path: string = '', direction: string = 'asc', skip: string = '[^a-zA-Z0-9]+') => {

    if(!item1 || !item2){
        return 0;
    }

    //find elements with the content to sort
    const el1 = path ? item1.querySelector(path) : item1;
    const el2 = path ? item2.querySelector(path) : item2;

    if(!el1 || !el2){
        return 0;
    }

    let text1 = el1.textContent.trim().toLowerCase();
    let text2 = el2.textContent.trim().toLowerCase();

    if(skip){

        //regex expression that is used to remove irrelevant characters
        const regexExpr = new RegExp(skip, 'ig');
        text1 = text1.replace(regexExpr, '').trim();
        text2 = text2.replace(regexExpr, '').trim();
    }

    if(text1 === text2){
        return 0;
    }

    if(!direction){
        direction = 'asc';
    }

    //compare languages other than English
    if(''.localeCompare){

        if (direction === 'asc') {
            return text1.localeCompare(text2);
        }
        else {
            return text2.localeCompare(text1);
        }
    }
    else{
        if (direction === 'asc') {
            return text1 > text2 ? 1 : -1;
        }
        else {
            return text1 < text2 ? 1 : -1;
        }
    }
};

/**
 * sort numbers
 * @param item1
 * @param item2
 * @param path - can be any CSS selector - https://developer.mozilla.org/en-US/docs/Learn/CSS/Introduction_to_CSS/Selectors;
 * empty path means the whole element
 * @param direction - asc or desc
 * @return 0 if equal, <0 if item1 < item2, >0 if item1 > item2
 */
const numbersSort = (item1: HTMLElement, item2: HTMLElement, path: string = '', direction: string = 'asc') => {

    if(!item1 || !item2){
        return 0;
    }

    //find elements with the content to sort
    const el1 = path ? item1.querySelector(path) : item1;
    const el2 = path ? item2.querySelector(path) : item2;

    if(!el1 || !el2){
        return 0;
    }

    const number1str = el1.textContent.trim().toLowerCase();
    const number2str = el2.textContent.trim().toLowerCase();

    //remove other characters
    const number1 = parseFloat(number1str.replace(/[^-0-9.]+/g,''));
    const number2 = parseFloat(number2str.replace(/[^-0-9.]+/g,''));

    if(isNaN(number1) || isNaN(number2)){

        if(isNaN(number1) && isNaN(number2)){
            return 0;
        }
        else {
            return isNaN(number1) ? 1 : -1;
        }
    }

    if(number1 === number2){
        return 0;
    }

    if(!direction){
        direction = 'asc';
    }

    if(direction === 'asc'){
        return number1 - number2;
    }
    else{
        return number2 - number1;
    }
};
