import { Store } from 'redux';
import { DataGridControl, DataGridSlice, SortAction } from '../../../interfaces';

/*
 Dropdown Sort Control

 HTML:
 -----
 <select data-grid-control="sort" data-id="...">
    <option data-path=".card-title" data-direction="asc" data-type="text" data-skip="...regex..." selected>Sort by title asc</option>
    <option data-path=".card-title" data-direction="desc" data-type="text">Sort by title desc</option>
    <option data-path=".card-desc" data-direction="asc" data-type="text">Sort by description asc</option>
    <option data-path=".card-desc" data-direction="desc" data-type="text">Sort by description desc</option>
    <option data-path=".likes" data-direction="asc" data-type="number">Sort by genres asc</option>
    <option data-path=".likes" data-direction="desc" data-type="number">Sort by genres desc</option>
 </select>
*/

/**
 * Sort dropdown control interface
 */
interface SortDropdownControl extends HTMLElement {
    control: DataGridControl,
    store: Store,
    rootSlice: DataGridSlice,
    dataID: string,
    options: HTMLElement[],
    selectedIndex: number
}

window.datagridControls = window.datagridControls || {};

/**
 * update datagrid general state
 */
const update = ($control: SortDropdownControl) => {

    const $option = $control.options[$control.selectedIndex];
    const path = $option.getAttribute('data-path');
    const direction = $option.getAttribute('data-direction') || 'asc';
    const type = $option.getAttribute('data-type') || 'text';
    const skip = $option.getAttribute('data-skip');

    // update the store -------------------------------------
    const control = $control.control;
    const store = $control.store;
    const rootSlice = $control.rootSlice;
    const { actions } = rootSlice;
    const { updateActions } = actions;

    const action: SortAction = {
        control: control.type,
        dataID: $control.dataID,
        path,
        direction,
        type
    };

    if(skip){
        action.skip = skip;
    }

    store.dispatch(updateActions(action));
};

/**
 * on change
 */
const onChange = (evt: Event) => {
    update(evt.currentTarget as SortDropdownControl);
};

/**
 * restore control state
 */
const restore = (control: DataGridControl, store: Store, action: SortAction) => {
    if(!action) return;

    const $control = control.$control as SortDropdownControl;
    const path = action.path;
    const direction = action.direction || 'asc';
    const type = action.type || 'text';

    const optionIndex = Array.from($control.options).findIndex($option => {

        const oPath = $option.getAttribute('data-path');
        const oDirection = $option.getAttribute('data-direction') || 'asc';
        const oType = $option.getAttribute('data-type') || 'text';

        return oPath === path && oDirection === direction && oType === type;
    });

    if(optionIndex !== -1){
        $control.selectedIndex = optionIndex;
    }
};

/**
 * sort dropdown control
 */
window.datagridControls.sort = (control: DataGridControl, store: Store, rootSlice: DataGridSlice) => {

    // re-initiate on each store update
    store.subscribe(() => {
        const state = store.getState();
        restore(control, store, state.actions[$control.dataID]);
    });

    const $control = control.$control as SortDropdownControl;
    $control.control = control;
    $control.store = store;
    $control.rootSlice = rootSlice;
    $control.dataID = $control.getAttribute('data-id');

    const state = store.getState();

    if($control.dataID !== undefined && state.actions[$control.dataID] !== undefined){

        // restore sort control state first time
        restore(control, store, state.actions[$control.dataID]);
    }
    else{

        // if initial state is not defined in settings, or via deep link / storage, then
        // try to get it from HTML
        update($control);
    }

    const initialIndex = $control.selectedIndex;

    $control.removeEventListener('change', onChange);
    $control.addEventListener('change', onChange);

    return {
        reset: () => {
            $control.selectedIndex = initialIndex;
            update($control);
        },
    }
};