import { DataGridActions } from "../interfaces";

/**
 * Return true if param is an object.
 */
export const isObject = (obj: any) => {
    return !!(obj && typeof obj === 'object' && !Array.isArray(obj) && Object.prototype.toString.call(obj) !== '[object Date]');
};

/**
 * performs a deep merge of objects and returns new object.
 * does not modify objects (immutable).
 */
export const deepMerge = (...objects: any[]) => {

    return objects.reduce((prev, obj) => {

        // loop through object properties
        for (let [key, value] of Object.entries(obj)) {

            const prevValue = prev[key];

            // if properties are objects
            if(isObject(prevValue) && isObject(value)){

                // merge them recursively
                prev[key] = deepMerge(prevValue, value);
            }
            else{
                prev[key] = value;
            }
        }

        return prev;

    }, {});
};

/**
 * check is string is numeric
 */
export const isNumeric = (str: any) => {
    return !isNaN(parseFloat(str)) && isFinite(str);
};

/**
 * get actions by the specified type, for example, 'sort' actions
 * the function returns all types that CONTAIN the given type, for example 'my-sort', 'sort-new' etc. also will be returned
 */
export const getActionsByType = (actions: DataGridActions, type: string) => {

    const filtered = [];

    for (const action of Object.values(actions)) {
        if(action.control?.indexOf(type) !== -1){
            filtered.push(action);
        }
    }

    return filtered;
};

/**
 * scroll or jump to the top
 */
export const scrollTop = () => {
    window.scroll(0, 0);

    /*
    @media (prefers-reduced-motion: no-preference)
    :root {
        scroll-behavior: smooth;
    }*/
};

export const isWhiteSpace = (text: string) => {
    return text.trim().length === 0;
};
