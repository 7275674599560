import type { Store } from 'redux';
import { Settings, DataGridState, DataGridAction, DataGridActions, DataGridSlice } from './interfaces';
import { configureStore, createSlice } from '@reduxjs/toolkit';
import { deepMerge } from './domain/helper';
import { getFromStorage, saveToStorage } from './data/storage';
import { getGrid, updateGridItems } from './ui/grid';
import { getControls, handleDataID, renderControls } from './ui/controls';
import { bringData } from './data/ajax';
import { deepLinkToDefaults, stateToDeepLink } from './data/deep-link';

/**
 * get defaults
 */
const getDefaults = (settings: Settings) => {

    let defaults: Settings = {

        // pagination
        currentPage: 0,
        pageSize: Infinity,
        pagesRange: 10,

        // all other actions like sort, filters etc.
        actions: {},
        initialActions: {},

        // deep link and storage
        deepLink: false,
        storage: '', // '', 'local-storage', 'session-storage', or 'cookies'
        storageName: 'datagrid',

        // data source
        dataSource: 'html', // html, ajax
        dataSourceURL: '',

        // optional custom filter
        customFilter: '',

        /**
         * convert JSON to HTML
         * used in AJAX data source
         */
        dataToHTML: (data: any) => {
            return data.map((item: any) => {
                return `
                    <div class="col-md-4" data-grid-item>
                        <div class="card mb-4 shadow-sm">
                            <div class="card-body">
                                <h5 class="card-title">${item.title}</h5>
                                <p class="card-text">${item.body}</p>
                            </div>
                        </div>
                    </div>
                `;
            }).join('');
        },

        // callbacks
        onChange: () => {}
    };

    // merge defaults with settings
    defaults = deepMerge(defaults, settings);

    if(defaults.storage !== ''){
        const storageString = getFromStorage(defaults.storage, defaults.storageName);
        const storageData = storageString ? deepLinkToDefaults(storageString) : [];
        for(let i=0; i<storageData.length; i++){
            const pair = storageData[i];
            defaults[pair.key] = pair.value;
        }
    }

    if(defaults.deepLink){
        const deepLinkData = window.location.search ? deepLinkToDefaults(window.location.search) : [];

        // override storage defaults with deep link data if needed
        for(let i=0; i<deepLinkData.length; i++){
            const pair = deepLinkData[i];
            defaults[pair.key] = pair.value;
        }
    }

    return defaults;
};

/**
 * build initial state
 */
export const buildInitialState = (settings: Settings, $root: HTMLElement) => {

    const defaults: Settings = getDefaults(settings);
    const initialState: DataGridState = {
        defaults,
        $root
    };

    // get grid placeholder from the page
    initialState.grid = getGrid($root);
    if(!initialState.grid){
        return null;
    }

    // the dictionary of all actions like sort, filter etc.
    // each action is defined by control's "data-id" attribute
    initialState.actions = defaults.actions || {};
    initialState.initialActions = JSON.parse(JSON.stringify(defaults.actions || {}));

    // pagination
    initialState.currentPage = defaults.currentPage;
    initialState.pageSize = defaults.pageSize;
    initialState.pagesRange = defaults.pagesRange;
    initialState.totalSize = undefined; // total size after filters is exists any; in case of ajax data source, total size is undefined till ajax brings the data

    // totalSize in case of html data source; in case of ajax -> totalSize will be initialized after the first ajax request because it needs "totalSize"
    if(defaults.dataSource === 'html') {
        initialState.totalSize = initialState.grid.$items.length;
    }

    // get all controls from the page
    initialState.controls = getControls($root);

    // Each control should have "data-id" attribute.
    // If the user does not define it, then it should be added dynamically.
    // The "data-id" attribute should be unique with the only exception:
    // when the same control appears in the top and the bottom panels at the same time.
    handleDataID(initialState.controls);

    return initialState;
};

/**
 * main update
 */
const update = async (state: DataGridState, store: Store, rootSlice: DataGridSlice) => {

    const dataSource = state.defaults.dataSource;

    // update grid helper classes
    state.grid.$grid.classList.add(`data-grid-${dataSource}`);

    if(dataSource === 'html') {

        // show or hide (and update in general) grid items according to the state
        const $visibleItems = updateGridItems(store, rootSlice);
        state.grid.$grid.classList.toggle(`data-grid-empty`, $visibleItems.length <= 0);
    }

    if(dataSource === 'ajax') {

        const data = await bringData(state);
        const totalSize = data.length;

        if(state.totalSize === undefined || state.totalSize !== totalSize){
            const { actions } = rootSlice;
            const { updateTotalSize } = actions;

            store.dispatch(updateTotalSize({
                totalSize
            }));
        }

        if(typeof state.defaults.dataToHTML === 'function'){
            state.grid.$grid.innerHTML = state.defaults.dataToHTML(data);
        }
        else{
            console.error('DataGrid Error: dataToHTML function is not provided.');
        }
    }

    if(dataSource !== 'ajax' || dataSource === 'ajax' && state.totalSize !== undefined) {

        if(state.defaults.deepLink){

            // update deep link
            const deepLink = stateToDeepLink(state);

            if (window.location.search !== deepLink && 'URL' in window) {
                const url = new URL(window.location.href);
                url.search = deepLink;
                window.history.replaceState('', '', url.href);
            }
        }

        if(state.defaults.storage !== ''){

            // update storage
            const deepLink = stateToDeepLink(state);
            saveToStorage(state.defaults.storage, state.defaults.storageName, deepLink);
        }
    }

    if(typeof state.defaults.onChange === 'function'){
        state.defaults.onChange(state);
    }
};

/**
 * entry point
 */
export const main = async (settings: Settings, $root: HTMLElement) => {
    
    const initialState = buildInitialState(settings, $root);
    if(!initialState) return;

    const rootSlice : DataGridSlice = createSlice({
        name: 'root',
        initialState,
        reducers: {
            updateTotalSize: (state, action: DataGridAction) => {
                return {
                    ...state,
                    totalSize: action.payload.totalSize
                }
            },
            updatePagination: (state, action: DataGridAction) => {
                const currentPage = action.payload.currentPage;
                const pageSize = action.payload.pageSize;
                const pagesRange = action.payload.pagesRange;

                return {
                    ...state,
                    currentPage,
                    pageSize,
                    pagesRange
                }
            },
            updateActions: (state, action: DataGridAction) => {
                const dataID = action.payload.dataID;
                const actions = {...state.actions} as DataGridActions;
                actions[dataID] = action.payload;
                return {
                    ...state,
                    actions,
                    currentPage: 0
                }
            }
        }
    });

    const store: Store = configureStore({
        reducer: rootSlice.reducer
    });

    // render controls according to the state
    renderControls(store, rootSlice);

    // first update
    await update(initialState, store, rootSlice);

    store.subscribe(async () => {
        const state = store.getState();
        await update(state, store, rootSlice);
    });
};
