import { Store } from 'redux';
import { DataGridControl, DataGridSlice, SortAction } from '../../../interfaces';

/*
 Sort Button Control

 HTML:
 -----
 <a
    href="#"
    data-id="..."
    data-grid-control="sort-button"
    data-path=".first-name"
    data-direction="asc"
    data-toggle-direction
    data-skip="..."
    data-type="text">First Name</a>

 CSS classes: dg-asc, dg-desc, dg-sort-selected
*/

/**
 * Sort button control interface
 */
interface SortButtonControl extends HTMLElement {
    control: DataGridControl,
    store: Store,
    rootSlice: DataGridSlice,
    dataID: string,
    direction: string, // 'asc', 'desc'
    toggleDirection: boolean,
    path: string,
    type: string, // 'text', 'number'
}

window.datagridControls = window.datagridControls || {};

// class names
const ASC = 'dg-asc';
const DESC = 'dg-desc';
const CHECKED = 'dg-sort-selected';

/**
 * update control classes
 */
const updateClasses = ($control: SortButtonControl, path: string) => {

    $control.classList.remove(ASC);
    $control.classList.remove(DESC);
    $control.classList.remove(CHECKED);

    if($control.path === path){
        $control.classList.add(CHECKED);
    }

    if($control.direction === 'asc'){
        $control.classList.add(ASC);
    }

    if($control.direction === 'desc'){
        $control.classList.add(DESC);
    }
};

/**
 * update datagrid general state
 */
const update = ($control: SortButtonControl) => {

    const skip = $control.getAttribute('data-skip');

    // update the store -------------------------------------
    const control = $control.control;
    const store = $control.store;
    const rootSlice = $control.rootSlice;
    const { actions } = rootSlice;
    const { updateActions } = actions;

    const action: SortAction = {
        control: control.type,
        dataID: $control.dataID,
        path: $control.path,
        direction: $control.direction,
        type: $control.type
    };

    if(skip){
        action.skip = skip;
    }

    store.dispatch(updateActions(action));

    // update control classes -------------------------------------
    updateClasses($control, $control.path);
};

/**
 * on click event
 */
const onClick = (evt: Event) => {
    evt.preventDefault();

    const $control = evt.currentTarget as SortButtonControl;

    if($control.toggleDirection){
        $control.direction = $control.direction === 'asc' ? 'desc' : 'asc';
    }

    update($control);
};

/**
 * restore control state
 */
const restore = (control: DataGridControl, store: Store, action: SortAction) => {
    if(!action) return;
 
    const $control = control.$control as SortButtonControl;
    $control.direction = action.direction || 'asc';
    updateClasses($control, action.path);
};

/**
 * sort button control
 */
window.datagridControls['sort-button'] = (control: DataGridControl, store: Store, rootSlice: DataGridSlice) => {

    // re-initiate on each store update
    store.subscribe(() => {
        const state = store.getState();
        restore(control, store, state.actions[$control.dataID]);
    });

    const $control = control.$control as SortButtonControl;
    $control.control = control;
    $control.store = store;
    $control.rootSlice = rootSlice;
    $control.dataID = $control.getAttribute('data-id');
    $control.direction = $control.getAttribute('data-direction') || 'asc';
    $control.toggleDirection = $control.hasAttribute('data-toggle-direction');
    $control.path = $control.getAttribute('data-path');
    $control.type = $control.getAttribute('data-type') || 'text';

    const state = store.getState();
    const initialDirection = $control.direction;

    if($control.dataID !== undefined && state.actions[$control.dataID] !== undefined){

        // restore sort control state first time
        restore(control, store, state.actions[$control.dataID]);
    }
    else{

        // if initial state is not defined in settings, or via deep link / storage, then
        // try to get it from HTML
        update($control);
    }

    $control.removeEventListener('click', onClick);
    $control.addEventListener('click', onClick);

    // returns an API ..........
    return {
        reset: () => {
            if($control.dataID && state.initialActions && state.actions[$control.dataID] !== undefined){
                restore(control, store, state.actions[$control.dataID]);
            }
            else{
                $control.direction = initialDirection;
                update($control);
            }
        },
    }
};