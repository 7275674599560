/**
 * Pagination
 * @param currentPage - current page index
 * @param pageSize - items number per page
 * @param totalSize - total items number
 * @param pagesRange - the number of pages that are visible at once on pagination control
 */
export const pagination = (currentPage: number, pageSize: number, totalSize: number, pagesRange: number) => {

    totalSize = Number(totalSize) || 0;
    pageSize = Number.isInteger(pageSize) ? Number(pageSize) : totalSize;

    if(pageSize === 0){
        pageSize = totalSize;
    }

    const pagesNumber = pageSize === 0 ? 0 : Math.ceil(totalSize / pageSize);

    //validate current page
    currentPage = Number(currentPage) || 0;

    if(currentPage > pagesNumber - 1){
        currentPage = 0;
    }

    const firstItemIndex = currentPage * pageSize;
    let lastItemIndex = firstItemIndex + pageSize;

    //validate the last item index
    if(lastItemIndex > totalSize){
        lastItemIndex = totalSize;
    }

    const prevPage = currentPage <= 0 ? 0 : currentPage - 1;
    const nextPage = pagesNumber === 0 ? 0 : (currentPage >= pagesNumber - 1 ? pagesNumber - 1 : currentPage + 1);

    pagesRange = Number(pagesRange) || 10;

    const halfRange = Math.ceil((pagesRange - 1) / 2);
    let pagesRangeStart = currentPage - halfRange;
    let pagesRangeEnd = Math.min(pagesRangeStart + pagesRange - 1, pagesNumber - 1);

    if(pagesRangeStart <= 0){
        pagesRangeStart = 0;
        pagesRangeEnd = Math.min(pagesRange - 1, pagesNumber - 1);
    }

    if(pagesRangeEnd >= pagesNumber - 1){
        pagesRangeStart = Math.max(pagesNumber - pagesRange, 0);
        pagesRangeEnd = pagesNumber - 1;
    }

    return {
        currentPage,
        pageSize,
        totalSize,
        pagesNumber,
        firstItemIndex,
        lastItemIndex,
        prevPage,
        nextPage,
        pagesRange,
        pagesRangeStart,
        pagesRangeEnd
    };
};