import { FilterAction } from '../../../interfaces';
import { ILexerResult, scan } from './filter-interpreter/lexer';
import { IParserResult, parse } from './filter-interpreter/parser';
import { IParseTreeNode } from './filter-interpreter/parse-tree-node';
import { TokenType } from './filter-interpreter/token';

/**
 * text filter
 * @param $girdItems
 * @param text
 * @param path - any CSS selector or empty value meaning the whole element
 * @param mode - contains (default), startsWith, endsWith, equal
 * @param skip - optional regex that defines what characters should be ignored
 * @return filtered items
 */
export const textFilter = ($girdItems: HTMLElement[], text: string, path: string = '', mode: string = 'contains', skip: string = '[^a-zA-Z0-9]+') => {

    const filtered = [];

    if(!$girdItems) return [];

    if(text === undefined || text.trim() === '') return $girdItems;

    const formattedText = text.replace(new RegExp(skip, 'ig'), '').toLowerCase().trim();

    for(let item of $girdItems){

        const $elements = path ? item.querySelectorAll(path) : [item];
        if(!$elements) continue;

        let shouldBeAdded = false;

        for(let i=0; i<$elements.length; i++) {
            const $el = $elements[i];
            const elText = $el.textContent.replace(new RegExp(skip, 'ig'), '').toLowerCase().trim();

            switch(mode){

                case 'startsWith':{

                    if(elText.startsWith(formattedText)){
                        shouldBeAdded = true;
                    }

                    break;
                }

                case 'endsWith':{

                    if(elText.endsWith(formattedText)){
                        shouldBeAdded = true;
                    }

                    break;
                }

                case 'equal':{

                    if(elText === formattedText){
                        shouldBeAdded = true;
                    }
                    break;
                }

                default:{

                    //contains
                    if(elText.indexOf(formattedText) !== -1){
                        shouldBeAdded = true;
                    }

                    break;
                }
            }

            if(shouldBeAdded) break;
        }

        if(shouldBeAdded){
            filtered.push(item);
        }
    }

    return filtered;
};

/**
 * path filter
 * only items with the given path are returned
 * @param girdItems
 * @param path - any CSS selector or empty value meaning the whole element
 * @param isInverted - if true, return all items that DON'T contain the specified path
 * @return filtered items
 */
export const pathFilter = (girdItems: HTMLElement[], path: string = '', isInverted: boolean = false) => {

    const filtered = [];

    if(!girdItems) return [];

    if(path === '' || !path) return girdItems;

    for(let item of girdItems){

        const el = item.querySelector(path);

        if(el && !isInverted || !el && isInverted){
            filtered.push(item);
        }
    }

    return filtered;
};


// -------------- AND / OR LOGIC ------------------------------------

const evaluate = (parserResult: IParserResult, filterActionsMap: Map<string, FilterAction>, $girdItems: HTMLElement[]) : IParseTreeNode|null => {

    if(!parserResult || !parserResult.root) return null;

    const helper = (root: IParseTreeNode) : IParseTreeNode|null => {
        if(!root) return null;

        if(!root.left && !root.right){
            if(!root.token.value) return null;

            const currentAction = filterActionsMap.get(root.token.value.toString());
            if(!currentAction) return null;

            const hasValue = currentAction.value !== undefined && currentAction.value !== null;
            if(hasValue){
                root.isEnabled = currentAction.value !== undefined && currentAction.value.trim() !== '';
                if(root.isEnabled){
                    root.$girdItems = textFilter(
                        $girdItems,
                        currentAction.value,
                        currentAction.path,
                        currentAction.mode,
                        currentAction.skip
                    );
                }
            }
            else{
                root.isEnabled = currentAction.path !== '' && !!currentAction.path;
                if(root.isEnabled){
                    root.$girdItems = pathFilter(
                        $girdItems,
                        currentAction.path,
                        currentAction.inverted
                    );
                }
            }

            //console.log(root.token, root.$girdItems.length, `is text filter: ${ hasValue }`)

            return root; // return `{${ root.token.value }}`;
        }

        const leftNode = root.left ? helper(root.left) : null;
        const rightNode = root.right ? helper(root.right) : null;

        const isLeftEnabled = !!leftNode && leftNode.isEnabled;
        const isRightEnabled = !!rightNode && rightNode.isEnabled;

        if(root.token.type === TokenType.Or){

            if(!isLeftEnabled && !isRightEnabled){
                root.isEnabled = false;
                root.$girdItems = [];
                return root;
            }

            if(!isLeftEnabled){
                root.isEnabled = isRightEnabled;
                root.$girdItems = rightNode.$girdItems || [];
                return root;
            }

            if(!isRightEnabled){
                root.isEnabled = isLeftEnabled;
                root.$girdItems = leftNode.$girdItems || [];
                return root;
            }

            root.isEnabled = true;
            root.$girdItems = Array.from(new Set([...leftNode.$girdItems || [], ...rightNode.$girdItems || []]));
            return root;
        }

        // AND ----- find intersection of two arrays ------------------------
        if(!isLeftEnabled && !isRightEnabled){
            root.isEnabled = false;
            root.$girdItems = $girdItems;
            return root;
        }

        if(!isLeftEnabled){
            root.isEnabled = isRightEnabled;
            root.$girdItems = rightNode.$girdItems || $girdItems;
            return root;
        }

        if(!isRightEnabled){
            root.isEnabled = isLeftEnabled;
            root.$girdItems = leftNode.$girdItems || $girdItems;
            return root;
        }

        root.isEnabled = true;

        root.$girdItems = [];

        const intersectionMap: Map<HTMLElement, boolean> = new Map();
        for(const $item of leftNode.$girdItems || $girdItems){
            intersectionMap.set($item, true);
        }

        for(const $item of rightNode.$girdItems || $girdItems){
            if(intersectionMap.has($item)){
                root.$girdItems.push($item);
            }
        }

        return root;
    };

    return helper(parserResult.root);
};

/**
 * Find all filters that are not used in the custom expression.
 */
const checkAllFiltersAreUsed = (filterActions: FilterAction[], lexerResult: ILexerResult) : string[] => {

    const tokens = lexerResult.tokens;
    const notUsedFilters: string[] = [];

    const set = new Set<string>();
    for(const token of tokens){
        if(token.type !== TokenType.ID) continue;
        set.add(token.value.toString().trim());
    }

    for(const action of filterActions){
        const dataID = action.dataID.toString().trim();
        if(!set.has(dataID)){
            notUsedFilters.push(dataID);
        }
    }

    return notUsedFilters;
};

/**
 * Apply a custom AND/Or filter logic.
 */
export const customAndOrFilter = (customFilter: string, filterActions: FilterAction[], $girdItems: HTMLElement[]): HTMLElement[] => {
    if(!$girdItems) return [];

    const filterActionsMap: Map<string, FilterAction> = new Map(); // data-id ---> filter action

    for(const filterAction of filterActions){
        filterActionsMap.set(filterAction.dataID, filterAction);
    }

    try{
        const lexerResult = scan(customFilter);
        if(lexerResult.isError){
            console.error(lexerResult.errors);
            console.log('Note that the data-id properties must contain only English letters, numbers, dashes, or underscores.');
            return $girdItems;
        }

        // check if all filter actions are used in the custom filter expression
        const notUsedFilters = checkAllFiltersAreUsed(filterActions, lexerResult);
        if(notUsedFilters.length > 0){
            console.error(`WARNING: the following filters are not used in the AND/OR expression:`, notUsedFilters);
            console.log(`When a custom filter expression is defined, any filters that are not used in the expression will not work.`);
        }

        const parserResult = parse(lexerResult);
        if(parserResult.isError){
            console.error(lexerResult.errors);
            return $girdItems;
        }

        const result = evaluate(parserResult, filterActionsMap, $girdItems);
        if(!result || !result.$girdItems) return [];
        return result.$girdItems || [];
    }
    catch(ex){
        console.error(`DataGrid: Custom filter definition contains an error.`);
        return $girdItems;
    }
};
