import { ILexerResult } from './lexer';
import { createParseTreeNode, IParseTreeNode, ParseTreeNodeType } from './parse-tree-node';
import { TokenType } from './token';

export interface IParserResult {
    root: IParseTreeNode;
    isError: boolean;
    errors: string[];
}

/**
 * Recursive Descent Parser.
 * Parser should create a parse tree.
 * A recursive descent parser is a kind of top-down parser built from a set of mutually recursive procedures (or a non-recursive equivalent) where each such procedure implements one of the non-terminals of the grammar.

 The grammar:
 -----------------------------
 expr -> term | term "or" expr
 term -> factor | factor "and" term
 factor -> "(" expr ")" | ID
 */
export const parse = (lexerResult: ILexerResult) : IParserResult => {

    let result: IParserResult = {
        root: null,
        isError: false,
        errors: [],
    };
    const tokens = lexerResult.tokens;
    const length = tokens.length;

    // when there is an error in lexer
    if(!lexerResult || lexerResult.isError){
        result.errors.push(...lexerResult.errors);
        result.isError = true;
        return result;
    }

    // empty string case
    if(!lexerResult.tokens || lexerResult.tokens.length <= 0) return result;

    // ----------------------------- HELPERS ---------------------------------------------

    const error = () => {
        result.isError = true;
        const token = tokens[index];
        result.errors.push(`ERROR: unexpected token: ${ token?.value ?? '' }, index: ${ index }`);
    };

    // --------------------- PARSER FLOW -------------------------------

    let index = 0;

    /**
     * expr -> term | term "or" expr
     */
    const expr = () : IParseTreeNode|null => {
        if(index >= length){
            error();
            return null;
        }

        const termNode = term();

        // expr -> term
        if(index >= length){
            return termNode;
        }

        // expr -> term "or" expr
        if(tokens[index].type === TokenType.Or){
            const token = tokens[index];
            index++;
            const rightNode = expr();
            return createParseTreeNode(ParseTreeNodeType.BinaryOperator, token, termNode, rightNode);
        }
        else{
            return termNode;
        }
    };

    /**
     * term -> factor | factor "and" term
     */
    const term = () : IParseTreeNode|null => {

        if(index >= length){
            error();
            return null;
        }
        const factorNode = factor();

        // term -> factor
        if(index >= length){
            return factorNode;
        }

        // term -> factor "and" term
        if(tokens[index].type === TokenType.And){
            const token = tokens[index];
            index++;
            const rightNode = term();
            return createParseTreeNode(ParseTreeNodeType.BinaryOperator, token, factorNode, rightNode);
        }
        else{
            // term -> factor
            return factorNode;
        }
    };

    /**
     * factor -> "(" expr ")" | ID
     */
    const factor = () : IParseTreeNode|null => {
        if(index >= length){
            error();
            return null;
        }

        // factor -> ID
        if(tokens[index].type === TokenType.ID){
            const tokenNode = createParseTreeNode(ParseTreeNodeType.ID, tokens[index]);
            index++;
            return tokenNode;
        }

        // factor -> "(" expr ")"
        if(tokens[index].type === TokenType.OpenParentheses){
            index++;

            const exprNode = expr();

            if(tokens[index].type === TokenType.CloseParentheses){
                index++;
                return exprNode;
            }
            else{
                error();
                return null;
            }
        }
        else{
            error();
            return null;
        }
    };

    const start = () => {
        result = {
            root: null,
            isError: false,
            errors: [],
        };

        result.root = expr();
    };

    start();

    return result;
};

export const printTree = (parserResult: IParserResult) : string => {

    if(!parserResult || !parserResult.root) return '';

    const helper = (root: IParseTreeNode) : string => {
        if(!root) return '';
        if(!root.left && !root.right){
            return `{${ root.token.value }}`;
        }

        const left = root.left ? helper(root.left) : '';
        const right = root.right ? helper(root.right) : '';
        return `(${ left } ${ root.token.type.toLowerCase() } ${ right })`;
    };

    return helper(parserResult.root);
};