import { DataGridControl, DataGridSlice, DataGridState } from '../../interfaces';
import { Store } from '@reduxjs/toolkit';

/*
 Reset Button Control

 HTML:
 -----
 <button type="button" data-grid-control="reset-button">Reset</button>
*/

window.datagridControls = window.datagridControls || {};

/**
 * Checkbox filter control interface
 */
interface ResetButtonControl extends HTMLElement {
    control: DataGridControl,
    store: Store,
    rootSlice: DataGridSlice,
    dataID: string,
}

const onClick = (evt: Event) => {
    const $control = evt.currentTarget as ResetButtonControl;

    const store = $control.store;
    const state = store.getState() as DataGridState;
    if(!state.controls) return;

    for(const control of state.controls){
        const $control = control.$control;
        if(!$control.datagrid || !$control.datagrid.reset || typeof $control.datagrid.reset !== 'function') continue;
        $control.datagrid.reset();
    }
};

/**
 * reset button control
 */
window.datagridControls['reset-button'] = (control: DataGridControl, store: Store, rootSlice: DataGridSlice) => {

    const $control = control.$control as ResetButtonControl;
    $control.control = control;
    $control.store = store;
    $control.rootSlice = rootSlice;
    $control.dataID = $control.getAttribute('data-id');

    $control.removeEventListener('click', onClick);
    $control.addEventListener('click', onClick);
};