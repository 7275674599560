import { Store } from '@reduxjs/toolkit';
import { DataGridControl, DataGridSlice, FilterAction } from '../../../interfaces';

/*
 Radio Button Filter Control

 HTML:
 -----
 <input type="radio" data-grid-control="radio-filter" data-path=".card-title" data-inverted="false" checked name="my-group" />
 Instead of data-id -> name attribute is used.
*/

/**
 * Radio button filter control interface
 */
interface RadioButtonFilterControl extends HTMLElement {
    control: DataGridControl,
    store: Store,
    rootSlice: DataGridSlice,
    dataID: string,
    checked: boolean,
    name: string,
}

window.datagridControls = window.datagridControls || {};

/**
 * update datagrid general state
 */
const update = ($control: RadioButtonFilterControl) => {
    const path = $control.getAttribute('data-path');
    const inverted = $control.getAttribute('data-inverted') === 'true';
    const value = $control.getAttribute('data-value');

    // update the store -------------------------------------
    const control = $control.control;
    const store = $control.store;
    const rootSlice = $control.rootSlice;
    const { actions } = rootSlice;
    const { updateActions } = actions;

    const action: FilterAction = {
        control: control.type,
        dataID: $control.name,
        path: $control.checked ? path : ''
    };

    if(inverted){
        action.inverted = inverted;
    }

    if(value !== undefined && value !== null){
        action.value = value;
        action.mode = $control.getAttribute('data-mode') || 'equal';

        const skip = $control.getAttribute('data-skip');
        if(skip !== undefined && skip !== null){
            action.skip = skip;
        }
    }

    if($control.checked){
        store.dispatch(updateActions(action));
    }
};

/**
 * on change event
 */
const onChange = (evt: Event) => {
    update(evt.currentTarget as RadioButtonFilterControl);
};

/**
 * restore control state
 */
const restore = (control: DataGridControl, store: Store, action: FilterAction) => {

    /*
    if(!action) return;

    const $control = control.$control as RadioButtonFilterControl;

    if($control.hasAttribute('checked')){
        $control.checked = (control.$control as HTMLInputElement).checked;
    }
    else{
        const path = action.path;
        const value = $control.getAttribute('data-value');
        const hasValue = value !== undefined && value !== null;

        let checked = path !== undefined && path !== null;

        if(checked && !hasValue){
            checked = path?.trim() !== '';
        }

        if(checked && hasValue && action.value !== undefined && action.value !== null){
            checked = action.value === value;
        }

        $control.checked = checked;
    }*/
};

/**
 * radio button filter control
 */
window.datagridControls['radio-filter'] = (control: DataGridControl, store: Store, rootSlice: DataGridSlice) => {

    const $control = control.$control as RadioButtonFilterControl;
    $control.control = control;
    $control.store = store;
    $control.rootSlice = rootSlice;
    $control.dataID = $control.name;

    const state = store.getState();

    if($control.dataID !== undefined && state.actions[$control.dataID] !== undefined){

        // restore control state for the first time
        restore(control, store, state.actions[$control.dataID]);
    }
    else{
        // if initial state is not defined in settings, or via deep link / storage, then
        // try to get it from HTML
        update($control);
    }

    const isInitialChecked = $control.checked;

    $control.removeEventListener('change', onChange);
    $control.addEventListener('change', onChange);

    return {
        reset: () => {
            if($control.dataID && state.initialActions && state.initialActions[$control.dataID] !== undefined){
                restore(control, store, state.initialActions[$control.dataID]);
            }
            else{
                if(isInitialChecked){
                    $control.checked = isInitialChecked;
                    update($control);
                }
            }
        },
    }
};