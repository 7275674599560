import { isNumeric } from '../domain/helper';
import { DataGridActions, DataGridState, DeepLinkParams } from '../interfaces';

/**
 * generate deep link from state
 */
export const stateToDeepLink = (state: DataGridState) => {

    if(!state || !window.URLSearchParams) return '';

    const params : DeepLinkParams = {
        currentPage: state.currentPage,
        pageSize: state.pageSize,
        pagesRange: state.pagesRange
    };

    if(state.actions){
        for (const action of Object.values(state.actions)) {

            const dataID = action.dataID;
            if(dataID === undefined || dataID === null) continue;

            for (const [key, value] of Object.entries(action)) {

                if(key !== 'dataID'){
                    params[`${key}-${dataID}`] = value;
                }
            }
        }
    }

    return new URLSearchParams(params).toString();
};

/**
 * if value is a number or a boolean -> parse it
 */
const parseValue = (value: any) => {

    if(isNumeric(value)){
        value = Number(value);
    }

    if(value === 'true'){
        value = true;
    }

    if(value === 'false'){
        value = false;
    }

    return value;
};

/**
 * convert deep link to defaults
 */
export const deepLinkToDefaults = (deepLink: string) => {

    const pairs = [];
    const actions: DataGridActions = {};

    const parts = deepLink.replace('?', '').split('&');

    for(let i=0; i<parts.length; i++){
        const part = parts[i].split('=');
        if(part.length !== 2) continue;

        const key = part[0];
        const value = part[1];

        if(key === 'currentPage' || key === 'pageSize' || key === 'pagesRange'){
            pairs.push({
                key,
                value: parseValue(value)
            });
        }
        else{
            // actions -------------------
            const index = key.lastIndexOf('-');
            if(index === -1) continue;

            const dataID = key.substring(index + 1);
            const propertyName = key.substring(0, index);

            if(actions[dataID] === undefined){
                actions[dataID] = {
                    dataID
                };
            }

            actions[dataID][propertyName] = parseValue(value);
        }
    }

    pairs.push({
        key: 'actions',
        value: actions
    });

    return pairs;
};
