import { Settings } from './interfaces';
import { main } from './main';
import './domain/controls/pagination/pagination-control';
import './domain/controls/pagination/page-size-control';
import './domain/controls/labels/label-control';
import './domain/controls/sort/sort-dropdown';
import './domain/controls/sort/sort-button';
import './domain/controls/filter/text-filter';
import './domain/controls/filter/select-filter';
import './domain/controls/filter/checkbox-filter';
import './domain/controls/filter/radio-filter';
import './domain/controls/filter/button-filter';
import './domain/controls/reset-button-control';
import '../css/datagrid.css';

/**
 * DataGrid Library
 *
 * Usage examples:
 * -----------------
 * window.datagrid();
 * window.datagrid({ ...settings... });
 * window.datagrid('.path-to-root');
 * window.datagrid('.path-to-root', { ...settings... });
 */
window.datagrid = (param1: any, param2: any) => {
  
  let $roots;

  // settings can be passed in the first or in the second param
  const settings = ((typeof param1 === 'object' ? param1 : param2) || {}) as Settings;

  // no path is passed -> used the whole document
  if(!param1 || typeof param1 === 'object'){
    $roots = [document.body];
  }
  else {
    $roots = document.querySelectorAll(param1) as NodeListOf<HTMLElement>;
  }

  for(let i=0; i<$roots.length; i++){
    const $root = $roots[i];
    main(settings, $root);
  }
};
