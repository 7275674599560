import { Store } from 'redux';
import { DataGridControl, DataGridSlice, DataGridState } from '../../../interfaces';
import { scrollTop } from '../../helper';
import { pagination } from '../../actions/pagination/pagination';

/*
 Pagination Control

 HTML:
 -----
 <nav
    aria-label="pagination"
    data-grid-control="pagination"
    data-prev="&laquo;"
    data-next="&raquo;"
    data-first="First"
    data-last="Last"
    data-hide-first-last="true"
    data-scroll-top>
 </nav>
 
 Settings
 --------
{
    currentPage: 0,
    pageSize: 10,
    pagesRange: 10,
    renderPaginationControl: (state, $control) => {
        return '...html...';
    }
}
*/

window.datagridControls = window.datagridControls || {};

/**
 * render HTML
 */
const render = (state: DataGridState, $control: HTMLElement) => {

    const paging = pagination(state.currentPage, state.pageSize, state.totalSize, state.pagesRange);
    $control.classList.toggle('dg-pagination-empty', paging.pagesNumber <= 0);

    if(state.totalSize <= 0) return '';

    const isFirstPage = paging.currentPage === 0;
    const isLastPage = paging.currentPage === paging.pagesNumber - 1;

    const buttons = [];
    for(let i = paging.pagesRangeStart; i<= paging.pagesRangeEnd; i++){
        buttons.push({
            index: i,
            title: i + 1
        });
    }

    const firstText = $control.getAttribute('data-first') || 'First';
    const lastText = $control.getAttribute('data-last') || 'Last';
    const prevText = $control.getAttribute('data-prev') || '&laquo;';
    const nextText = $control.getAttribute('data-next') || '&raquo;';
    const hideFirstLast = $control.getAttribute('data-hide-first-last') === 'true';

    const ulClass = $control.getAttribute('data-ul-class') || 'pagination';
    const liClass = $control.getAttribute('data-li-class') || 'page-item';
    const linkClass = $control.getAttribute('data-link-class') || 'page-link';

    const disabledClass = $control.getAttribute('data-disabled-class') || 'disabled';
    const activeClass = $control.getAttribute('data-active-class') || 'active';

    const prevClass = $control.getAttribute('data-prev-class') || 'page-prev';
    const nextClass = $control.getAttribute('data-next-class') || 'page-next';
    const firstClass = $control.getAttribute('data-first-class') || 'page-first';
    const lastClass = $control.getAttribute('data-last-class') || 'page-last';

    return `
        <ul class="${ulClass}" data-grid-control="pagination">
        
        ${ !hideFirstLast ? `<li class="${liClass} ${ isFirstPage ? disabledClass : '' } ${firstClass}">
                ${ isFirstPage ? `<span class="${linkClass}">${firstText}</span>` : `<a class="${linkClass}" href="#" data-grid-page="${0}">${firstText}</a>`}
            </li>` : ''
        }
            
            <li class="${liClass} ${ isFirstPage ? disabledClass : '' } ${prevClass}">
                ${ isFirstPage ? `<span class="${linkClass}">${prevText}</span>` : `<a class="${linkClass}" href="#" data-grid-page="${paging.currentPage - 1}">${prevText}</a>`}
            </li>
            
            ${
                buttons.map( button => {
        
                    const isCurrentPage = paging.currentPage === button.index;
        
                    return `
                        <li class="${liClass} ${ isCurrentPage ? activeClass : '' }" ${ isCurrentPage ? 'aria-current="page"' : '' }>
                            ${ !isCurrentPage ? `<a class="${linkClass}" href="#" data-grid-page="${button.index}">${button.title}</a>` : '' }
                            ${ isCurrentPage ? `<span class="${linkClass}" data-grid-page="${button.index}">${button.title}<span class="sr-only">(current)</span></span>` : '' }
                        </li>
                        `
                    }).join('')
            }
            
            <li class="${liClass} ${ isLastPage ? disabledClass : '' } ${nextClass}">
                ${ isLastPage ? `<span class="${linkClass}">${nextText}</span>` : `<a class="${linkClass}" href="#" data-grid-page="${paging.currentPage + 1}">${nextText}</a>`}
            </li>
            
            ${ !hideFirstLast ? `<li class="${liClass} ${ isLastPage ? disabledClass : '' } ${lastClass}">
                ${ isLastPage ? `<span class="${linkClass}">${lastText}</span>` : `<a class="${linkClass}" href="#" data-grid-page="${paging.pagesNumber - 1}">${lastText}</a>`}
            </li>` : ''
            }
        </ul>
    `;
};

/**
 * init pagination control
 * generate pagination control HTML according to the state
 */
const init = (control: DataGridControl, store: Store, rootSlice: DataGridSlice) => {

    const state = store.getState();
    const { actions } = rootSlice;
    const { updatePagination } = actions;

    /*
    currentPage: 0
    firstItemIndex: 0
    lastItemIndex: 10
    nextPage: 1
    pageSize: 10
    pagesNumber: 10
    pagesRange: 10
    pagesRangeEnd: 9
    pagesRangeStart: 0
    prevPage: 0
    totalSize: 100
    */

    control.$control.innerHTML = typeof state.defaults.renderPaginationControl === 'function' ? state.defaults.renderPaginationControl(state, control.$control) : render(state, control.$control);

    const $buttons = control.$control.querySelectorAll('[data-grid-page]');
    const shouldScrollTop = control.$control.hasAttribute('data-scroll-top');

    for(let i=0; i<$buttons.length; i++){
        const $button = $buttons[i];

        $button.addEventListener('click', evt => {
            evt.preventDefault();
            const $target = evt.currentTarget as HTMLElement;
            const currentPage = Number($target.getAttribute('data-grid-page')) || 0;

            store.dispatch(updatePagination({
                currentPage,
                pageSize: state.pageSize,
                pagesRange: state.pagesRange
            }));

            if(shouldScrollTop){
                scrollTop();
            }
        });
    }
};

/**
 * pagination control
 * generate pagination control HTML according to the state
 */
window.datagridControls.pagination = (control: DataGridControl, store: Store, rootSlice: DataGridSlice) => {

    const state = store.getState();
    const initialCurrentPage = state.currentPage;

        // re-initiate on each store update
    store.subscribe(() => {
        init(control, store, rootSlice);
    });

    // initiate first time
    init(control, store, rootSlice);

    return {
        reset: () => {
            const state = store.getState();
            const { actions } = rootSlice;
            const { updatePagination } = actions;

            store.dispatch(updatePagination({
                currentPage: initialCurrentPage,
                pageSize: state.pageSize,
                pagesRange: state.pagesRange
            }));
        },
    }
};